#Header {
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #f1f1f1f1;
    background-color: #FFF;
}

.logo {
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
}

h1 {
    color: black;
    position: absolute;
    top: 6px;
    left: 50%;
    margin-left: -155px;
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
}