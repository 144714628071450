#Accordion {
    width: 570px;
}

#Accordion > div {
    margin: 1em;
    border-radius: 10px;
    padding: 20px;
}

#Accordion > div::before {
    background-color: inherit;
}

#Accordion .MuiAccordionSummary-content .MuiTypography-body1 {
    font-weight: 700;
    font-size: 19px;
}

#Accordion .MuiAccordionSummary-content {
    margin: 0;
}

/* #Accordion .MuiButtonBase-root {
    min-height: 0 !important;
} */

#Accordion .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
}

/* ### */
.info {
    background: #EDF2FF;
    padding: 10px;
    border-radius: 8px;
    padding-left: 66px
}

.info-icon {
    position: absolute;
    left: 58px;
}

.accordion-item {
    padding: 14px 0;
}

.accordion-item-col1 {
    width: 160px;
    display: inline-block;
}

/* margin from expand icon */
.MuiAccordionSummary-expandIconWrapper {
    margin-left: 10px;
}